import React, { ComponentType, FC } from "react";

import { Block, GridWrapper } from "../../components/GridComponents";
import styled from "../../styling/styled";
import { rem } from "../../styling/theme";

const SymbolBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  font-size: ${rem(14)};
  line-height: ${rem(24)};
  ${({ theme }): string[] => [theme.media.maxM]} {
    margin-bottom: ${rem(40)};
    font-size: ${rem(16)};
  }
`;

// width is similar as bootstrap grid system
export type SymbolBlockT = {
  title: string;
  width: number;
  symbol: ComponentType;
};

type SymbolBlocksT = {
  blocks: SymbolBlockT[];
};

const SymbolBlocks: FC<SymbolBlocksT> = ({ blocks }) => {
  const EDGES = 40;

  return (
    <GridWrapper opositeMargin={EDGES}>
      {blocks.map((block, index) => {
        const SVG = block.symbol;
        const Symbol = styled(SVG)`
          width: 70px;
          height: 50px;
          margin-bottom: ${rem(10)};

          path {
            fill: ${({ theme }): string => theme.colors.black};
          }
        `;

        return (
          <Block
            flexDirection="row"
            width={block.width}
            key={index}
            paddingRight={EDGES}
            paddingLeft={EDGES}
            withBorder
          >
            <SymbolBlock>
              <Symbol />
              {block.title}
            </SymbolBlock>
          </Block>
        );
      })}
    </GridWrapper>
  );
};

export default SymbolBlocks;
