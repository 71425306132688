import { useIntl } from "gatsby-plugin-intl";
import React, { FC } from "react";

import { FourFinanceEffective } from "../../assets/images/Images";
import Container from "../../components/Container";
import { Block, GridWrapper, Image } from "../../components/GridComponents";
import Spacer from "../../components/Spacer";
import HeadingBlock from "../general/HeadingBlock";

const EffectiveSection: FC = () => {
  const intl = useIntl();

  return (
    <Container>
      <Spacer size="extraLarge" />
      <GridWrapper>
        <Block>
          <HeadingBlock
            heading={intl.formatMessage({
              id: "4finance.effective_section.title"
            })}
            pre={intl.formatMessage({
              id: "4finance.effective_section.pre"
            })}
            paragraph={[
              intl.formatMessage({
                id: "4finance.effective_section.text_1"
              }),
              intl.formatMessage({
                id: "4finance.effective_section.text_2"
              }),
              intl.formatMessage({
                id: "4finance.effective_section.text_3"
              })
            ]}
          />
          <Spacer size="medium" />
        </Block>
        <Block>
          <Image
            src={FourFinanceEffective}
            alt={intl.formatMessage({
              id: "4finance.effective_section.title"
            })}
          />
        </Block>
      </GridWrapper>
    </Container>
  );
};

export default EffectiveSection;
