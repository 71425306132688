import React, { FC } from "react";

import Layout from "../../components/Layout";
import EffectiveSection from "../../partials/FourFinance/EffectiveSection";
import HiglightsSection from "../../partials/FourFinance/HighlightsSection";
import EnterSection from "../../partials/general/EnterSection";
import Introduction from "../../partials/general/Introduction";
import SuccessStoriesFooter from "../../partials/general/SuccessStoriesFooter";

const HomePage: FC = () => {
  const ID = "4finance";

  return (
    <Layout
      helmetKey={ID}
      emptyMenu={true}
      blackMenu={true}
      lightColorVariant
      menuIconType="/success-stories"
      render={(): React.ReactChild => (
        <>
          <Introduction caseStudyId={ID} />
          <EnterSection caseStudyId={ID} />
          <EffectiveSection />
          <HiglightsSection />
          <SuccessStoriesFooter />
        </>
      )}
    />
  );
};

export default HomePage;
