import { useIntl } from "gatsby-plugin-intl";
import React, { FC } from "react";

import {
  ChefSymbol,
  ContentfulCMSSymbol,
  ECMAScript2015Symbol,
  NightwatchJsSymbol,
  ReactSymbol,
  TranslationServerSymbol
} from "../../assets/images/Images";
import Container from "../../components/Container";
import Spacer from "../../components/Spacer";
import { putEmptyLineInsteadSpace } from "../../utils/putEmptyLineInsteadSpace";
import HeadingBlock from "../general/HeadingBlock";
import SymbolBlocks, { SymbolBlockT } from "../general/SymbolBlocks";

const HiglightsSection: FC = () => {
  const intl = useIntl();

  const blocks: SymbolBlockT[] = [
    {
      title: "React",
      width: 2,
      symbol: ReactSymbol
    },
    {
      title: "ECMAScript 2015",
      width: 2,
      symbol: ECMAScript2015Symbol
    },
    {
      title: "Contentful CMS",
      width: 2,
      symbol: ContentfulCMSSymbol
    },
    {
      title: "Chef",
      width: 2,
      symbol: ChefSymbol
    },
    {
      title: "Nightwatch.js",
      width: 2,
      symbol: NightwatchJsSymbol
    },
    {
      title: "Translation server",
      width: 2,
      symbol: TranslationServerSymbol
    }
  ];

  return (
    <Container>
      <Spacer size="extraLarge" />
      <HeadingBlock
        heading={putEmptyLineInsteadSpace(
          intl.formatMessage({ id: "4finance.technology_section.title" }),
          1
        )}
        pre={intl.formatMessage({ id: "4finance.technology_section.pre" })}
      />
      <Spacer mobileSize="medium" size="extraLarge" />
      <SymbolBlocks blocks={blocks} />
      <Spacer size="extraLarge" />
    </Container>
  );
};

export default HiglightsSection;
